/* 
//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//
*/

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* This selectors is to force that the drowdown in the left side bar menu, where you can select the organization
   isn't opening when hovering over (the openOnFocus property on the component doesn't seems to work correctly).
   Please see the './dashboard/OrganizationSelector.tsx' file */
#orgDropdownId.ui.simple.dropdown:hover > .menu {
  display: none !important;
}

#orgDropdownId.ui.simple.dropdown.visible:hover > .menu {
  display: block !important;
}
